<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image v-for="(bannerItem,bannerIndex) in topData.banners" :key="bannerIndex+'1'" :src="bannerItem.image">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 介绍 -->
      <!-- <div class="neiron" v-html="topData.content"></div> -->
      <!-- introduce -->
      <!-- 使用方法 -->
      <div v-if="topData.introduce" class="usage_box">
        <div class="title" v-if="topData.introduce" v-html="topData.introduce"></div>
        <!-- <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div v-if="usage[lang]" class="txt">{{usage[lang]}}</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div> -->
      </div>
      <div class="neiron" v-html="topData.content"></div>
      <!-- 列表 -->
      <div class="main_box" v-for="(item,index) in listData" :key="index">
        <!-- 图片 -->
        <div v-if="item.image" class="img_box">
          <el-image :src="item.image" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        <div class="xiao_btn_img" v-if="item.media_data">
          <a v-for="itemA in item.media_data" :target="itemA.value == 'javascript:;' || itemA.value == '#TravelContents' || !itemA.value?'':'_blank'" :href="!itemA.value?'javascript:;':itemA.value"><img :src="itemA.icon" alt=""></a>
          <!-- <a v-if="item.media_data1" target="_blank" :href="item.iconVideoHref"><img :src="xiao_btn_img1" alt=""></a>
          <a v-if="item.media_data2" href="#stepImgId"><img :src="xiao_btn_img2" alt=""></a>
          <a v-if="item.media_data3" href="javascript:;"><img :src="xiao_btn_img3" alt=""></a> -->
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-html="item.title"></div>
          <div style="color:red;font-weight: 700;" v-html="item.subtitle"></div>
          <hr>
          <div class="message_box">
            <div class="message_item"  v-html="item.content"></div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
import { getProductList, getProductListTop } from "@/api/hfIndex";
export default {
  data() {
    return {
      id:'',
      lang:'sc',
      usage: {
        'sc':'使用方法',
        'tc':'使用方法',
        'en':'How to use',
        'kr':'사용방법',
        'jp':'使用方法'
      },
      topData: {},
      listData: {},
      // headImg: require('@/assets/images/hfkansai/zh/havefun_title-sc2.png'),
      loadImg: require("@/assets/images/hfkansai/loading.png"),
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.lang = this.$route.query.l;
    console.log(this.id, this.lang);
    this.getTopDataFn(this.id, this.lang);
    this.getListDataFn(this.id, this.lang);
  },
  methods: {
    async getTopDataFn(id,lang) {
      let res = await getProductListTop({ id, lang});
      this.topData = res.data.data;
      console.log(res);
    },
    async getListDataFn(id,lang) {
      let res = await getProductList({ id, lang, page:1 });
      this.listData = res.data.data.list;
      console.log('this.listData',this.listData);
    },
  },
};
</script>
<style lang="scss" scoped>
.xiao_btn_img{
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-top: 12px;
  a{
    height: unset;
    // flex: 1;
    width: 33%;
    // text-align: center;
    &:hover{
      border-bottom: 0;
    }
  }
  img{
    // flex: 1;
    width: 90%;
    max-width: 144px;
    height: auto;
    // height: 100%;
  }
}
/deep/.head_img_box{
    max-width: 766px;
    height: 100%;
    margin: 0 auto;
    padding: 0;
  img{
    width: 100% !important;
    height: auto;
  }
}
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #fffba0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1a1311;
    font-weight: 700;
    margin-top: 0;
    .title {
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span {
        color: #fa6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }

    .text_box {
      position: relative;
      margin-top: 12px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color: #ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>